import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import DepositUserData from "../component/DepositUserData";
import AdminHeader from '../layout/AdminHeader';
import AdminSidebar from '../layout/AdminSidebar';
import AdminFooter from '../layout/AdminFooter';
import common from '../services/common';
import { Button, Modal } from 'react-bootstrap';
import { Store } from 'react-notifications-component';

const DirectDeposit = () => {
  var user_data = localStorage.getItem("user_data");
  var user_data_json = JSON.parse(user_data);
  const navigate = useNavigate();
  const [isPasswordVerified, setIsPasswordVerified] = useState(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(true); // Set the modal to open by default
  const [password, setPassword] = useState('');
  const correctPassword = user_data_json.deposit_password; // Replace with your actual hardcoded password

  const handlePasswordVerification = () => {
    if (password === correctPassword) {
      setIsPasswordVerified(true);
      setIsPasswordModalOpen(false); // Close the modal when the password is verified
    } else {
        Store.addNotification({
            title: "Error!",
            message: "Incorrect password. Please try again.",
            type: "danger",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true
            }
        });
    }
  };

  const promptForPassword = () => {
    const password = prompt('Enter a password for the Excel file:');
    return password;
  };

  const [downloadUrl, setDownloadUrl] = useState('');

  const handleExportExcel = async () => {
    const password = promptForPassword();

    if (!password) {
      alert('Password is required to export the data.');
      return;
    }

    const params = {
      is_all_data: true,
      password: password
    }

    var getportaluserlistdata = await common.getdeposituserlist(params);
    if (getportaluserlistdata?.data?.status == 400) {
      navigate('/user/logout');
      return;
    }

    if (getportaluserlistdata?.data?.status == 202) {
      // Ask the user for a password
      // const password = "";
      var url = getportaluserlistdata.data.data.filePath;
      setDownloadUrl(getportaluserlistdata.data.data.filePath);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Direct Deposit Submissions.xlsx'); // Specify the desired file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } else {
      console.log("Failed ");
    }
  };

  useEffect(() => {
    (async () => {
    })();
  }, []);

  return (
    <div className="adminview pipeline-view wrapper">
      <AdminHeader />
      <AdminSidebar />
      <div className="content-wrapper px-3 pt-1 pb-3">
        <div className="content-header">
          <div className="row">
            <div className="col-sm-6 pl-0">
              <h1 className="m-0">Direct Deposit</h1>
            </div>
            <div className="col-sm-6 pr-0">
              <div className="float-right">
                {isPasswordVerified ? (
                  <button className="btn btn-primary" onClick={handleExportExcel}>
                    Export to Excel <i className="fas fa-plus-square"></i>
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {isPasswordVerified ? (
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <DepositUserData fetchUrl="api/get-all-user" columns={["Name", "Status", "Action"]}></DepositUserData>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <AdminFooter />

      {/* Modal for Password Entry */}
      <Modal
        show={isPasswordModalOpen} // Show the modal when it's open
        onHide={() => setIsPasswordModalOpen(false)} // Close the modal by clicking outside or pressing Esc
        animation={false} // Disable animation for simplicity
      >
        <Modal.Header>
          <Modal.Title>Enter Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="password"
            placeholder="Enter Password"
            className="form-control text-box single-line"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsPasswordModalOpen(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handlePasswordVerification}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DirectDeposit;
