import React,{useEffect} from 'react';
import { useForm } from "react-hook-form";
import { useNavigate,NavLink } from "react-router-dom";
import { useAppState } from "../hooks/state";

const EmploymentApplicationStep4 = () => {
    const today = new Date();
    const year = today.getFullYear();
    const navigate = useNavigate();
    const [state, setState] = useAppState();
    const {
        register,
        getValues,
        handleSubmit,
        formState: { errors }
      } = useForm({
        defaultValues: state,
      });
      const [dynamicField, setDynamicField] = useAppState();
      const onSubmit = (data) => {
        setState({ ...state, ...data });
        navigate("/employment/professional");
        
    }


    useEffect(() => {
        
 


        if (Object.keys(dynamicField).length == 0) {
                
         }
        
        
        
            }, []);
  return (
        <div className="web-version">
            
            <div className="header-main">
              <div className="header-top">
                  <div className="container">
                  <nav className="navbar navbar-expand-sm navbar-dark">
                      <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/user/login">Login</NavLink>
                        </li>
                        <li className="nav-item">
                            <span className="nav-link"><small>|</small></span>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link pr-0" to="/">Register</NavLink>
                        </li>
                      </ul>
                      </nav>
                  </div>
              </div>
              <div className="header-middle">
                  <div className="container">
                  <div className="d-flex align-items-center">
                    <div className="mr-auto">
                      <NavLink className="brand-logo-link" to="/">
                        <img src="../Poss logo-01.png?v=1" alt="Logo" className="brand-logo" />
                      </NavLink>
                    </div>
                    <div className="ml-auto">
                        <div className="web-header-title">EMPLOYMENT APPLICATION</div>
                    </div>
                    </div>
                  </div>
              </div>
            </div>

            <div className="web-page-content">
                <div className="container">
                    <div className="web-page-inner">
                    <form onSubmit={handleSubmit(onSubmit)}>
                <div className="steps-content step-content-2">
                    <ul className="progressbar">  
                        <li className="steps step-1 active"><span>1</span><strong>General <br/>Information</strong></li>  
                        <li className="steps step-2 active"><span>2</span><strong>Position </strong></li>  
                        <li className="steps step-3 active"><span>3</span><strong>Education and <br/>Training</strong></li>  
                        <li className="steps step-4 active"><span>4</span><strong>Background</strong></li>
                        <li className="steps step-5"><span>5</span><strong>Professional <br/>References</strong></li> 
                        <li className="steps step-6"><span>6</span><strong>Applicant's Certification <br/>Agreement</strong></li> 
                    </ul>  
                    
                    
                    <div className="card card-primary">
                    <div className="card-header with-border clearfix">
                        <div className="card-title">Background</div>
                    </div>
                    <div className="card-body">
                    <div className="row">
                              <div className="col-md-8">
                                <div className="form-group">
                                    <label>Have you ever been convicted of a crime (other than a moving violation) or served time? <span className="required">*</span></label>
                                    <div className="raw">
                                     
                                        <div className="form-check-inline">
                                        <label className="form-check-label" htmlFor="convicted_crime_yes">  <input type="radio" className="form-check-input" name="convicted_crime" id="convicted_crime_yes" value="1" {...register('crime', { required: "Please select crim." })} />
                                         Yes</label>
                                        </div>

                                        <div className="form-check-inline">
                                        <label className="form-check-label" htmlFor="convicted_crime_no">  <input type="radio" className="form-check-input" name="convicted_crime" id="convicted_crime_no" value="0"  {...register('crime', { required: "Please select crim." })} />
                                       No</label>
                                        </div>
                                    </div>

                                    {errors.crime?.message && (
     <p className='text-danger'>{errors.crime.message}</p>
  )}
                                </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label>(If yes, please describe. Conviction of a crime does not automatically disqualify you from employment.) <span className="required">*</span></label>
                                    <input className="form-control text-box single-line" type="text" {...register('crime_yes',{ validate: {
          required: value => {
            if (!value && getValues('crime')==='1') return 'Please describe.';
            return true;
          },
        }})} />
                                   {errors.crime_yes?.message && (
     <p className='text-danger'>{errors.crime_yes.message}</p>
  )}
                                </div>
     
                            </div>
                        </div>
                    </div>
                    </div>
                  <div className="card-footer-step text-center">
                        <NavLink className="btn btn-primary" to="/employment/education"><i className="right fas fa-angle-left"></i> Previous</NavLink>
                        <button type="submit" className="btn btn-primary">Save & Next<i className="right fas fa-angle-right"></i></button>
                  </div>
                </div>
</form>
                
                </div>
              </div>
            </div>


            <div className="footer-main bg-dark">
                <div className="container">
                    <p className="text-center">Copyrights © {year} All rights reserved. POSS</p>
                </div>
            </div>

        </div>
    );
};

export default EmploymentApplicationStep4;
