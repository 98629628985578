 
  var user_data=localStorage.getItem("user_data");     
  var user_data_json=JSON.parse(user_data);

const Config = {
	API_URL : 'https://stagingposshrapi.theopenxpert.com/',
    //API_URL : 'http://poss.local/', 

    API_AUTH:"dxFz8kNPeMqRCsTlqV0HP909rcCSqNfaBXSAC8qqt3A",
    APP_TITLE: '',
    user_data:user_data_json,
    auto_refresh_time:60000,
    intervalId:0,

  }
  
  export default Config
  

 
