import React,{useState,useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useNavigate, NavLink } from "react-router-dom";
import { useAppState } from "../hooks/state";
import { Store } from 'react-notifications-component';

const EmploymentApplicationStep5 = () => {

    const today = new Date();
    const year = today.getFullYear();
    const navigate = useNavigate();
    const [state, setState] = useAppState();
    const {
        register,
        handleSubmit,
        formState: { errors }
      } = useForm({
        defaultValues: state,
      });
      const [dynamicField, setDynamicField] = useAppState();
      const onSubmit = (data) => {
     
        var professional_references = data.professional_references
        if(professional_references.length < 3){
          // setLoading(false);

          Store.addNotification({
              title: "Error!",
              message: "Atleast three professional references should be required",
              type: "danger",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                  duration: 3000,
                  onScreen: true
              }
          });
          return false;
      }
        // console.log(data);
        setState({ ...state, ...data });
     navigate("/employment/certification");
        
    }

    const arr =[];

   var fields= {
        name:'',
        email:'',
        phone:'',
        relationship:''
    };

    if( state.professional_references)
    {

    state.professional_references.map((data, index)=>{
        arr.push(fields)
    });
    }else
    {
        arr.push(fields);
    }

    const [inputFields, setInputFields] = useState([
        {
          name: '',
          email: '',
          phone: '',
          relationship: ''
        },
        {
          name: '',
          email: '',
          phone: '',
          relationship: ''
        },
        {
          name: '',
          email: '',
          phone: '',
          relationship: ''
        }
      ]);
      
    const addInputField = ()=>{
        setInputFields([...inputFields, {
            name:'',
            email:'',
            phone:'',
            relationship:'' 
        } ])
      
    }
    const removeInputFields = (index)=>{
        const rows = [...inputFields];

        console.log(index);

        rows.splice(index, 1);
        setInputFields(rows);
   }
   useEffect(() => {
        
 


    if (Object.keys(dynamicField).length == 0) {      
     }
    
    
    
        }, []);

  return (
        <div className="web-version">
            
            <div className="header-main">
              <div className="header-top">
                  <div className="container">
                  <nav className="navbar navbar-expand-sm navbar-dark">
                      <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/user/login">Login</NavLink>
                        </li>
                        <li className="nav-item">
                            <span className="nav-link"><small>|</small></span>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link pr-0" to="/">Register</NavLink>
                        </li>
                      </ul>
                      </nav>
                  </div>
              </div>
              <div className="header-middle">
                  <div className="container">
                  <div className="d-flex align-items-center">
                    <div className="mr-auto">
                      <NavLink className="brand-logo-link" to="/">
                        <img src="../Poss logo-01.png?v=1" alt="Logo" className="brand-logo" />
                      </NavLink>
                    </div>
                    <div className="ml-auto">
                        <div className="web-header-title">EMPLOYMENT APPLICATION</div>
                    </div>
                    </div>
                  </div>
              </div>
            </div>

            <div className="web-page-content">
                <div className="container">
                    <div className="web-page-inner">
                    <form onSubmit={handleSubmit(onSubmit)}>
                <div className="steps-content step-content-2">
                    <ul className="progressbar">  
                        <li className="steps step-1 active"><span>1</span><strong>General <br/>Information</strong></li>  
                        <li className="steps step-2 active"><span>2</span><strong>Position </strong></li>  
                        <li className="steps step-3 active"><span>3</span><strong>Education and <br/>Training</strong></li>  
                        <li className="steps step-4 active"><span>4</span><strong>Background</strong></li>
                        <li className="steps step-5 active"><span>5</span><strong>Professional <br/>References</strong></li> 
                        <li className="steps step-6"><span>6</span><strong>Applicant's Certification <br/>Agreement</strong></li> 
                    </ul>  
                    
                    
                    <div className="card card-primary">
                    <div className="card-header with-border clearfix">
                        <div className="card-title">Professional References</div>
                    </div>
                    <div className="card-body">


                        <p>(Include only individuals familiar with your work capabilities. Do not include relatives.)</p>


                 


{
                      inputFields.map((data, index)=>{
                          const {name,email, phone, relationship}= data;
                          return(
                        <div className="row"  key={index}>

                              <div className="col-md-10">
                              <div className="row">
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Name <span className="required">*</span></label>
                                    <input className="form-control text-box single-line" name="name"   type="text"  {...register(`professional_references.[${index}].name`,{ required: "Please enter name." })} />
                                    
     <p className='text-danger'> {errors.professional_references?.[index]?.name?.message}</p>

    
 
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Email Address <span className="required">*</span></label>
                                    <input className="form-control text-box single-line" name="email"   type="text" {...register(`professional_references.[${index}].email`, {
                                        required: "Please enter email address.",
                                        validate: {
                                          maxLength: (v) =>
                                            v.length <= 50 || "The email should have at most 50 characters",
                                          matchPattern: (v) =>
                                            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                                            "Please enter a valid email address",
                                        },
                                      })}/>
                                    <p className='text-danger'> {errors.professional_references?.[index]?.email?.message}</p>
                               
                               
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Phone Number <span className="required">*</span></label>
                                    <input className="form-control text-box single-line" name="phone"   type="text" {...register(`professional_references.[${index}].phone`,{
                                        required: {
                                          value: true,
                                          message: "Please enter cell phone number."
                                        }, 
                                        pattern: {
                                          value: /^[0-9+-]+$/,
                                          message: "This is not a valid cell phone number."
                                        }, 
                                        minLength: {
                                          value: 10,
                                          message: "Please enter valid cell phone number."
                                        }, 
                                        maxLength: {
                                          value: 12,
                                          message: "Please enter valid cell phone number."
                                        } })}/>
                                    <p className='text-danger'> {errors.professional_references?.[index]?.phone?.message}</p>
                               
                               
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Years Known/Relationship <span className="required">*</span></label>
                                    <input className="form-control text-box single-line" name="relationship"   type="text" {...register(`professional_references.[${index}].relationship`,{ required: "Please enter relationship." })} />
                                    <p className='text-danger'> {errors.professional_references?.[index]?.relationship?.message}</p>
                                </div>
                            </div>
                            </div>
                            </div>
                            <div className="col-md-2">
                            <div className="form-group">
                                    <div><label>&nbsp;</label></div>
                            {(inputFields.length!==3)? <div className="btn btn-outline-danger" value={index}    onClick={(evt) => { removeInputFields(evt.target.value); }}    >Remove</div>:''}
                            </div>
                            </div>
                        </div>

)
})
}

                        <div className="row">
                            <div className="col-md-4">                       
       
                            <div className="btn btn-default" onClick={addInputField}><i className="right fas fa-plus"></i> Add more</div>
                            </div>
                        </div>
                    </div>
                    </div>
                  <div className="card-footer-step text-center">
                        <NavLink className="btn btn-primary" to="/employment/background"><i className="right fas fa-angle-left"></i> Previous</NavLink>
                        <button type="submit" className="btn btn-primary">Save & Next<i className="right fas fa-angle-right"></i></button>
                  </div>
                </div>

                </form>
                </div>
              </div>
            </div>


            <div className="footer-main bg-dark">
                <div className="container">
                    <p className="text-center">Copyrights © {year} All rights reserved. POSS</p>
                </div>
            </div>

        </div>
    );
};

export default EmploymentApplicationStep5;
