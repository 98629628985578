import React from 'react';
import {NavLink,useNavigate} from "react-router-dom";
import { useForm } from 'react-hook-form';
import { Store } from 'react-notifications-component';
import common from '../services/common';

const ForgotPassword = () => {

  const {
    control,
    register,
    getValues,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm();
  const navigate = useNavigate();
  const onSubmit = async (data) => {


    var forgetpasswordapi=   await common.forgetpassword(data); 


         
    if(forgetpasswordapi?.data?.status==202)
    {
      Store.addNotification({
         title: "Success!",
         message: forgetpasswordapi?.data?.message,
         type: "success",           
         container: "top-right",
         animationIn: ["animate__animated", "animate__fadeIn"],
         animationOut: ["animate__animated", "animate__fadeOut"],
         dismiss: {
           duration: 3000,
           onScreen: true
         }
       });


       navigate('/user/reset-password');
       
    
   
    }
    else
    {

    




    
  
      Store.addNotification({
         title: "Error!",
         message: forgetpasswordapi?.data?.message?.email,
         type: "danger",            
         container: "top-right",
         animationIn: ["animate__animated", "animate__fadeIn"],
         animationOut: ["animate__animated", "animate__fadeOut"],
         dismiss: {
           duration: 3000,
           onScreen: true
         }
       });
      
     
     
 
   }
    

    
  }




  return (
    
        <div className="login-page">

<div className="login-box">
  <div className="login-logo"> 
    <img src="/Poss logo-01.png" style={{maxWidth: 290}} alt="Logo Large" className="brand-image-xs" />
</div>
  <div className="card">
    <div className="card-body login-card-body">
      <h5 className="login-box-msg"><b>Forgot Password</b></h5>
      <form onSubmit={handleSubmit(onSubmit)} id="forget_form">
        <div className="  mb-3">
          <input type="email" className="form-control" placeholder="Email"  {...register("email", {
                                                    required: "Please enter email address.",
                                                    validate: {
                                                      maxLength: (v) =>
                                                        v.length <= 50 || "The email should have at most 50 characters",
                                                      matchPattern: (v) =>
                                                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                                                        "Please enter a valid email address",
                                                    },
                                                  })} />


{errors.email?.message && (
     <p className='text-danger'>{errors.email.message}</p>
     )}
           
        </div>
        <div className="row">
          <div className="col-12 mt-1 mb-2">
            

            <button type='submit' className="btn btn-primary btn-block">Submit</button>
          </div>
        </div>
      </form>
      <p className="mb-1 text-center"> <NavLink to="/user/login">Sign In</NavLink> </p>
    </div>
  </div>
</div>
          
       </div>
      
    );
};

export default ForgotPassword;
