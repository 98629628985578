import React, { useState, useEffect } from 'react';
import { NavLink,useNavigate } from "react-router-dom";

import AdminHeader from '../layout/AdminHeader';
import AdminSidebar from '../layout/AdminSidebar';
import AdminFooter from '../layout/AdminFooter';
import common from '../services/common';
import Config from '../config/config';
import LoadingIcon from '../component/LoadingIcon';
import { Modal } from 'react-bootstrap'
import BootstrapModal from "react-bootstrap/Modal";
import { useForm ,Controller} from 'react-hook-form';
import { Store } from 'react-notifications-component';
import Select from 'react-select';


const Dashboard = () => {

  const [ddshow, setDdshow] = useState(false);
  const ddhandleShow = () => setDdshow(true);
  const [dropDownOpt, setdropDownOpt] = useState([]);
  const [dropDownOpt2, setdropDownOpt2] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const openModal = (positionId, locationId) => {
    setPositionId(positionId);
    setLocationId(locationId);
    setShowModal(true);
  };
  

  const closeModal = () => {
    setShowModal(false);
  };

  const handleRemove = async (positionId, locationId) => {
    // Perform the removal action using positionId and locationId
    await removePositionLocation(positionId, locationId);
  
    // Close the modal after removal
    closeModal();
  };
  
  
  
  const [apidetails, setApidetails] = useState([])
  const [authCheck, setAuthCheck] = useState()
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [positionId, setPositionId] = useState(null);
  const [locationId, setLocationId] = useState(null);
  const [loading, setLoading] = useState(false);
  var user_data = localStorage.getItem("user_data");
  var user_data_json = JSON.parse(user_data);

  const ddhandleClose = () => {
    
    setTimeout(() => {
        setDdshow(false);
    }, 400);
  }

  const navigate = useNavigate();
  const {
    control,
    register,
    getValues,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm();


  const gotocandidatedetails = async (candidate_id) => {
    navigate('/admin/edit-application/'+candidate_id);
  }


  const onSubmit = async (data) => {
    setLoading(true); 
    if(data.position_id!=null )
    {
            let position_id_m = data.position_id.map(a => a.value);
            data.position_id=position_id_m;
    }
    if(data.location_id!=null )
    {
            let location_id_m = data.location_id.map(a => a.value);
            data.location_id=location_id_m;
    }
    var AddPortalUserapi=   await common.AddPositionLocation(data);  
    if(AddPortalUserapi?.data?.status==400)
    {
      navigate('/user/logout');
      return;
    }
   if(AddPortalUserapi?.data?.status==202)
   {
    Store.addNotification({
        title: "Success!",
        message: AddPortalUserapi?.data?.message,
        type: "success",           
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true
        }
      });
      fetchData()
     setLoading(false);
        setDdshow(false);
   }
   else
   {
    Store.addNotification({
        title: "Error!",
        message: AddPortalUserapi?.data?.message,
        type: "danger",            
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true
        }
      });
     setLoading(false);
  }
}

const removePositionLocation = async (positionId, locationId) => {

  var deleteid = new FormData();
  deleteid.append('location_id', locationId);
  deleteid.append('position_id', positionId);
  var UpdatePortalUserapi=   await common.deletePositionlocation(deleteid);  
  if(UpdatePortalUserapi?.data?.status==202)
  {
    Store.addNotification({
        title: "Success!",
        message: UpdatePortalUserapi?.data?.message,
        type: "success",           
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true
        }
      });
      fetchData();
    setLoading(false);
  }
  else
  {
   setLoading(false);
   Store.addNotification({
      title: "Error!",
      message: UpdatePortalUserapi?.data?.message,
      type: "danger",            
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
  }
};

const fetchData = async () => {

  var dashboarddata = await common.dashboard();
  var dropdownvalue= await common.getdropdown(null);     
  const dropDownValuedata = dropdownvalue.data.data.position_field_2.map((response) => ({
    "value" : response.id,
    "label" : response.name
  }))

  const dropDownValuedata2 = dropdownvalue.data.data.preferred_area_field.map((response) => ({
    "value" : response.id,
    "label" : response.name
  }))

  setdropDownOpt(dropDownValuedata);
  setdropDownOpt2(dropDownValuedata2);
  
  //console.log(dashboarddata.data.data);


  if(dashboarddata?.data?.status==400)
  {
    navigate('/user/logout');
    return;
  }

  setApidetails(dashboarddata.data.data);
}
  

 
  useEffect(() => {
    (async () => {


    if(!['1', '2','3'].includes(user_data_json.role_id))
    {
      navigate('/user/login');
       return;
    }


   clearInterval(Config.intervalId);
    
      setAuthCheck(user_data_json);

    fetchData()
  

   

    Config.intervalId= setInterval(() => {
      fetchData(); // Fetch data every 2 minutes
    }, Config.auto_refresh_time);

  
  
    
 
       
 


    })();

  }, []);


  return (

    <div className="adminview wrapper">
      <AdminHeader />
      <AdminSidebar />

      <div className="home content-wrapper px-3 pt-1 pb-3">
        <header className="poss-page-header">
          <div className="home-header">
            <div className="home-header-title">
              <h1>My Dashboard</h1>
            </div>
            <div className="home-header-stats">
              <ul className="metrics">
                <li className="metrics-label">
                  <h2>Recent Stats</h2>
                </li>
                <li className="metric-label">
                  <h2> {apidetails?.position_activated}</h2>
                </li>
                <li>
                  <p>Positions<br />
                    Activated</p>
                </li>
                <li className="metric-label">
                  <h2>{apidetails?.new_candidate}</h2>
                </li>
                <li>
                  <p>New<br />
                    Candidates</p>
                </li>
                <li className="metric-label">
                  <h2>{apidetails?.candidate_moved}</h2>
                </li>
                <li>
                  <p>Candidates<br />
                    Moved Forward</p>
                </li>
                <li className="metric-label">
                  <h2>{apidetails?.candidate_hired}</h2>
                </li>
                <li>
                  <p>Candidates<br />
                    Hired</p>
                </li>
              </ul>
            </div>
          </div>
        </header>


        <div className="flex-grid-wrapper">
        <div class="col-sm-12">
          <div className="col-sm-2">
              
          </div>
        </div>
        {apidetails?.positions ? (
          <div className="flex-grid">
            <div className="flex-column flex-2 positions-list-container">
              <div className="flex-cell">
                <div className="flex-scrollable-widget positions-content">
                  <div className="flex-header">
                    <h2>
                      <div className="dropdown row">
                        <div className="dropdown-title col-sm-6"><span className="segment-name ">All Positions ({user_data_json?.first_name})</span></div>
                        <div className='col-sm-6'>
                        <NavLink className="btn btn-link float-right" onClick={ddhandleShow}><i className="fas fa-plus-square"></i> Add Position</NavLink>
                        </div>
                      </div>
                    </h2>
                  </div>
                  <div className="flex-scroll">
                    <ul className="positions ">


                      {apidetails?.positions && apidetails.positions.map((p, key) => {
                        if (p.status === 1) {
                          return (
                            <li className="position card" key={key}>
                              <div className="card-box position">
                                <div className="position-color-bar-container">
                                  <ul className="color-bars">
                                    <li className="assigned-bar">
                                      <div className="bar manager"></div>
                                    </li>
                                  </ul>
                                </div>
                                <div className="position-info">
                                  <div className="position-header">
                                    <h3>
                                      <i className="icon fa fa-circle published"></i>
                                      <span>{p.name} - {p.location_name}</span>
                                      <a onClick={() => openModal(p.id, p.location_id)}>
                                        <span className="" style={{ float: "right", color: "red", 'font-size':'12px','cursor':'pointer' }}>
                                        Inactive<span></span>
                                        </span>
                                      </a>
                                    </h3>
                                  </div>
                                  <div className="clearfix">
                                    <div className="position-secondary-body">
                                    </div>
                                    <div className="position-footer">
                                      <ul className="statuses">
                                        {(authCheck?.role_id == 1 || authCheck?.role_id == 2) && (
                                          <li className="tab-link">
                                            <NavLink to={'/admin/applications-pipeline/' + p.id + '/' + p.location_id}>Pipeline</NavLink>
                                          </li>
                                        )}
                                        <li className="tab-link">
                                          <NavLink to={'/admin/applications-list/' + p.id + '/' + p.location_id}>Candidates</NavLink>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          );
                        } else {
                          return null; // If p.status is not 1, render nothing (null)
                        }
                      })}



                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-column align-positions">
              <div className="flex-cell auto-size">
                <div className="flex-scrollable-widget candidates-content">
                  <div className="flex-header">
                    <h5>
                      <div className="dropdown">
                        <div className="dropdown-title"><i className="fa fa-users"></i><span className="segment-name ">New Candidates</span></div>
                      </div>
                    </h5>
                  </div>
                  <div className="flex-scroll">
                    <ul className="list candidate-list ">


                      {apidetails?.candidate && apidetails.candidate.map((cd, key) => {
                        return <li className="card small still " key={key}>
                          <div className="card-box candidate" onClick={() =>gotocandidatedetails(cd.id)}>
                            <div className="unseen-marker"></div>
                            <div className="candidate-container">
                              <div className="candidate-color-bar-container">
                                <div person="candidate" size="sm" className="ng-isolate-scope sm avatar color4 good" initial={Array.from(cd.name)[0]}></div>
                              </div>
                              <div person="candidate"></div>
                              <div className="candidate-info">
                                <div className="candidate-header">
                                  <h3><span >{cd.name}</span></h3>
                                </div>
                                <div className="candidate-body">
                                  <p className="headline"><span ><i className="fa fa-briefcase"></i> {cd.position_name}
                                  </span></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>


          ) : (

            <LoadingIcon></LoadingIcon>
)}







<BootstrapModal show={showModal} onHide={closeModal} centered>
  <BootstrapModal.Header closeButton>
    <BootstrapModal.Title>Confirmation</BootstrapModal.Title>
  </BootstrapModal.Header>
  <BootstrapModal.Body>
    Are you sure you want to Inactive this position?
  </BootstrapModal.Body>
  <BootstrapModal.Footer>
    <button className="btn btn-danger" onClick={() => handleRemove(positionId, locationId)}>
      Confirm
    </button>
  </BootstrapModal.Footer>
</BootstrapModal>




        </div>
        <Modal show={ddshow} onHide={ddhandleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add Position</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(onSubmit)} id="add_user">
            <Modal.Body>
                <div className="row">
                  <div className="col-md-12">
                    <div className="label-wrapper">
                      <label className="col-form-label">Access Position <span className="required">*</span></label>
                    </div>
                    <Controller
                      name="position_id"
                      control={control}
                      rules={{ required: "Please select access position." }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={dropDownOpt}
                          defaultValue={1}
                          isMulti
                        />
                      )}
                    />
                    {errors.position_id?.message && (
                      <p className='text-danger'>{errors.position_id.message}</p>
                    )}
                  </div>
                  <div className="col-md-12">
                    <div className="label-wrapper">
                      <label className="col-form-label">Location <span className="required">*</span></label>
                    </div>
                    <Controller
                      name="location_id"
                      control={control}
                      rules={{ required: "Please select Location." }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={dropDownOpt2}
                          defaultValue={1}
                          isMulti
                        />
                      )}
                    />
                    {errors.position_id?.message && (
                      <p className='text-danger'>{errors.position_id.message}</p>
                    )}
                  </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="submit" className="btn btn-primary">Save</button>
                <span className="btn btn-default" onClick={ddhandleClose}>Cancel</span>
            </Modal.Footer>
            </form>
        </Modal>

      </div>
      <AdminFooter />
    </div>

  );
};

export default Dashboard;
