
import { NavLink, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import LoadingIcon from '../component/LoadingIcon';
import Config from "../config/config";
import AdminHeader from '../layout/AdminHeader';
import AdminSidebar from '../layout/AdminSidebar';
import AdminFooter from '../layout/AdminFooter';
import common from '../services/common';
import { Store } from 'react-notifications-component';
import { useForm } from 'react-hook-form';
const ApplicationsPipeline = () => {



 
  const [columns, setColumns] = useState();
  const [positionName, setPositionName] = useState();
  const [dropDownOpt2, setdropDownOpt2] = useState([]);
  let { id, locationid } = useParams();
  const [selectedLocation, setSelectedLocation] = useState(locationid);
  var user_data = localStorage.getItem("user_data");
  var user_data_json = JSON.parse(user_data);
  const navigate = useNavigate();
  const {
    control,
    register,
    getValues,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm();

  const handleLocationChange = async (selectedOption) => {
    setSelectedLocation(selectedOption.value); 
    fetchData(selectedOption.value);
  };

  const fetchData = async (locationIdSec = null) => {
    if(locationIdSec != null){
      var getcandidatepiplinedata = await common.getcandidatepipline(id, locationIdSec);
    }else{
      var getcandidatepiplinedata = await common.getcandidatepipline(id, locationid);
    }

    const dropDownValuedata2 = getcandidatepiplinedata.data.preferred_area_field.map((response) => ({
      "value" : response.id,
      "label" : response.name
    }))
    setdropDownOpt2(dropDownValuedata2);
    

    if(getcandidatepiplinedata?.data?.status==400)
    {
      navigate('/user/logout');
      return;
    }
    setColumns(getcandidatepiplinedata?.data?.data);
    setPositionName(getcandidatepiplinedata?.data?.position_name);

  };
  useEffect(() => {
    clearInterval(Config.intervalId);
    if(!['1','2'].includes(user_data_json.role_id))
    {
      navigate('/user/login');
       return;
    }

    fetchData()


    Config.intervalId = setInterval(() => {
       fetchData(); // Fetch data every 2 minutes
     }, Config.auto_refresh_time);
  }, [])








  const onDragEnd =async (result, columns, setColumns) => {
    console.log(result);
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {


      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];


      var status_id = destColumn?.id;
      var position_id = locationid;
      var candidate_id = result.draggableId;     




      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];





      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems
        }
      });

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    

      var formdata = new FormData();
      formdata.append('status_id', status_id);
      formdata.append('position_id', position_id);
      formdata.append('candidate_id', candidate_id);
      formdata.append('remarks', destColumn?.name);
      formdata.append('updated_by', user_data_json?.user_id);
      




      var Updatecandidatestatusapi=   await common.updatecandidatestatus(formdata);  


      if(Updatecandidatestatusapi?.data?.status==400)
      {
        navigate('/user/logout');
        return;
      }

     
      if(Updatecandidatestatusapi?.data?.status==202)
      {
        Store.addNotification({
           title: "Success!",
           message: Updatecandidatestatusapi?.data?.message,
           type: "success",           
           container: "top-right",
           animationIn: ["animate__animated", "animate__fadeIn"],
           animationOut: ["animate__animated", "animate__fadeOut"],
           dismiss: {
             duration: 3000,
             onScreen: true
           }
         });
         
      
       
      }
      else
      {
        Store.addNotification({
           title: "Error!",
           message: Updatecandidatestatusapi?.data?.message,
           type: "danger",            
           container: "top-right",
           animationIn: ["animate__animated", "animate__fadeIn"],
           animationOut: ["animate__animated", "animate__fadeOut"],
           dismiss: {
             duration: 3000,
             onScreen: true
           }
         });
          // Reset the page by fetching data again
        fetchData(locationid); // Assuming you have the locationId available
      }




    } else {

      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems
        }
      });
    }
  };






  return (

    <div className="adminview pipeline-view wrapper">
      <AdminHeader />
      <AdminSidebar />

      <div className="content-wrapper px-3 pt-1 pb-3">
        <div className="content-header">
          <div className="row">
            <div className="col-sm-9 pl-0">
              <h1 className="m-0">{positionName}</h1>
            </div>
            <div className="col-sm-3 row pr-0">
            </div>
          </div>
        </div>
        <div className="card mt-2">
          <div className="card-body overflow-auto">


            {columns ? (

              <div
                style={{ display: "flex", justifyContent: "start", height: "100%", gap: 10, }}
              >



                <DragDropContext
                  onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
                >
                  {Object.entries(columns).map(([columnId, column], index) => {
                    return (
                      <div className="pipeline-item"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          border: "1px solid #edeff0",
                        }}
                        key={columnId}
                      >
                        <h2>
                          <span className="stage-icon"><i className="far fa-user"></i></span> 
                          {column.name}
                          <span className="candidates-total">{column?.items?.length}</span>
                        </h2>
                        <div style={{ margin: 0 }}>
                          <Droppable droppableId={columnId} key={columnId}>
                            {(provided, snapshot) => {
                              return (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  style={{
                                    background: snapshot.isDraggingOver
                                      ? "#fff"
                                      : "#fff",
                                    padding: "0px 8px 0px 8px",
                                    width: 278,
                                    minHeight: 500
                                  }}
                                >
                                  {column.items.map((item, index) => {
                                    return (
                                      <Draggable
                                        key={item.id}
                                        draggableId={item.id.toString()}
                                        index={index}
                                      >
                                        {(provided, snapshot) => {
                                          return (

                                    <div className="card small still "   ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      userSelect: "none",
                                      padding: 0,
                                      margin: "0 0 8px 0",
                                      minHeight: "50px",
                                      backgroundColor: snapshot.isDragging
                                        ? "#fff"
                                        : "#fff",
                                      color: "#292d32",
                                      ...provided.draggableProps.style
                                    }}>
                                      <div className="card-box candidate"><div className="unseen-marker"></div>
                                      <div className="candidate-container">
                                        <div className="candidate-color-bar-container">
                                          <div person="candidate" className="ng-isolate-scope sm avatar color4 good" initial={Array.from(item.first_name)[0]}></div>
                                          </div>
                                          <div person="candidate"></div>
                                          <div className="candidate-info">
                                            <div className="candidate-header"><h3><span>{item.first_name} {item.last_name}</span></h3></div>
                                            <div className="candidate-body"><p className="headline">
                                              <span><i className="fa-solid fa-clock"></i> {item.days_difference}</span>    



                                              <span className="float-right">

                                              <NavLink to={'/admin/edit-application/'+item.id}><i className="fas fa-edit" style={{fontSize:15}}></i></NavLink>
                                                
                                                
                                                
                                                </span> 

                                          </p></div></div></div></div></div>
                    
                                          );
                                        }}
                                      </Draggable>
                                    );
                                  })}
                                  {provided.placeholder}
                                </div>
                              );
                            }}
                          </Droppable>
                        </div>
                      </div>
                    );
                  })}
                </DragDropContext>


              </div>
            ) : (

              <LoadingIcon />
            )}




          </div>
        </div>
      </div>

      <AdminFooter />
    </div>

  );
};



export default ApplicationsPipeline;
