import React, { useEffect } from 'react';
import { NavLink,useNavigate } from "react-router-dom";
import PayIncreaseData from "../component/PayIncreaseData";
import AdminHeader from '../layout/AdminHeader';
import AdminSidebar from '../layout/AdminSidebar';
import AdminFooter from '../layout/AdminFooter';


const PayIncrease = () => {

    var user_data = localStorage.getItem("user_data");
  var user_data_json = JSON.parse(user_data);
  const navigate = useNavigate();


  useEffect(() => {
    (async () => {



    })();

}, []);

  return (
    
        <div className="adminview pipeline-view wrapper">
          <AdminHeader />
          <AdminSidebar />
          
          <div className="content-wrapper px-3 pt-1 pb-3">
            <div className="content-header">
                <div className="row">
                    <div className="col-sm-6 pl-0">
                        <h1 className="m-0">Pay Increase List</h1>
                    </div>
                    <div className="col-sm-6 pr-0">
                        <div className="float-right">
                        {(user_data_json?.role_id==1 || user_data_json?.role_id==2)  &&
                          <NavLink className="btn btn-link" style={{ fontSize: '15px' }} to="/admin/add-pay-increase"><i className="fas fa-plus-square"></i> Add new</NavLink>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="table-responsive" style={{'overflow': "hidden"}}>
                    <PayIncreaseData
                        fetchUrl="api/get-position"
                        columns={["Employment type", "Employee name", "Starting date","Starting rate", "Increase rate", "Rate increase"]}
                    ></PayIncreaseData>
                    </div>
                </div>
            </div>
        </div>
        <AdminFooter />
       </div>
      
    );
};

export default PayIncrease;
