import React,{useState,useEffect} from 'react';
import {NavLink,useNavigate,useLocation,useParams  } from "react-router-dom";

const AdminHeader = () => {
    const navigate = useNavigate();
    var user_data=localStorage.getItem("user_data");     
    var user_data_json=JSON.parse(user_data);  

   

    const Logout = async () => {

        localStorage.removeItem('user_data');
        navigate('/user/login');
        return;

    }

 

  return (
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a className="nav-link" data-widget="pushmenu" href="#"><i className="fa fa-bars"></i></a>
                </li>
                {/*<li className="nav-item dropdown d-none d-sm-inline-block">
                    <a href="#" data-toggle="dropdown" aria-expanded="true" className="nav-link">User Role <small><i className="fa fa-chevron-down"></i></small></a>
                    <div className="dropdown-menu dropdown-menu-md dropdown-menu-left">
                        <NavLink className="dropdown-item active" to="/admin/dashboard">Admin</NavLink>
                        <div className="dropdown-divider"></div>
                        <NavLink className="dropdown-item" to="/hr/dashboard">HR</NavLink>
                        <div className="dropdown-divider"></div>
                        <NavLink className="dropdown-item" to="/staff/dashboard">Staff</NavLink>
                        <div className="dropdown-divider"></div>
                        <NavLink className="dropdown-item" to="/employee/edit-application">Employee</NavLink>
                    </div>

  </li>
                <li className="nav-item">
                    <a href="#" className="nav-link pl-0 disabled">Admin</a>
                </li>*/}
            </ul>
            <ul className="navbar-nav ml-auto">
                
                {/*<li className="nav-item">
                    <a className="nav-link" href="#"><i className="far fa-bell"></i><span className="badge badge-warning navbar-badge">5</span></a>
            </li>*/}
                <li className="nav-item">
                    <a href="#" className="nav-link disabled">{user_data_json?.first_name} {user_data_json?.last_name}</a>
                </li>
                <li className="nav-item">
                   

                    <a  className="nav-link"  onClick={Logout}>Logout</a>
                </li>
            </ul>
        </nav>

  );
};

export default AdminHeader;
