import React,{useState,useEffect } from 'react';
import {NavLink,useNavigate   } from "react-router-dom";
import { useForm ,Controller} from 'react-hook-form';
import { Store } from 'react-notifications-component';
import common from '../services/common';

const ResetPassword = () => {

  const {
    control,
    register,
    getValues,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm();

  const navigate = useNavigate();
  const onSubmit = async (data) => {

    console.log(data);


    var resetpasswordapi=   await common.resetpassword(data); 


         
    if(resetpasswordapi?.data?.status==202)
    {
      Store.addNotification({
         title: "Success!",
         message: resetpasswordapi?.data?.message,
         type: "success",           
         container: "top-right",
         animationIn: ["animate__animated", "animate__fadeIn"],
         animationOut: ["animate__animated", "animate__fadeOut"],
         dismiss: {
           duration: 3000,
           onScreen: true
         }
       });
       navigate("/user/login");
    
   
    }
    else
    {

    




    
  
      Store.addNotification({
         title: "Error!",
         message: resetpasswordapi?.data?.message?.code,
         type: "danger",            
         container: "top-right",
         animationIn: ["animate__animated", "animate__fadeIn"],
         animationOut: ["animate__animated", "animate__fadeOut"],
         dismiss: {
           duration: 3000,
           onScreen: true
         }
       });
      
     
     
 
   }
    

    
  }




  return (
    
        <div className="login-page">

<div className="login-box">
  <div className="login-logo"> 
    <img src="/Poss logo-01.png" style={{maxWidth: 290}} alt="Logo Large" className="brand-image-xs" />
</div>
  <div className="card">
    <div className="card-body login-card-body">
      <h5 className="login-box-msg"><b>Reset Password</b></h5>
      <form onSubmit={handleSubmit(onSubmit)} id="reset_form">

      
      <div className="row mb-3">
        <div className="col-12">
        Please enter the code that you received on your mail. Also set your new password.
          </div>

          </div>


      <div className="row mb-3">
        <div className="col-12">
         
      


        <input className="form-control text-box single-line" type="text"  placeholder='Code' {...register("code", { required: "Please enter code."})}/>
                                        {errors.code?.message && (
     <p className='text-danger'>{errors.code.message}</p>
  )}
           
        </div>

        </div>
        <div className="row mb-3">
        <div className="col-12 ">
         
       
 
 
 <input className="form-control text-box single-line password" type="password"  placeholder='New Password' {...register("password", { required: "Please enter password."})}/>
                                    {errors.password?.message && (
     <p className='text-danger'>{errors.password.message}</p>
     )}  
            
         </div>
         </div>



        
        <div className="row">
          <div className="col-12 mt-1 mb-2">
            

            <button type='submit' className="btn btn-primary btn-block">Submit</button>
          </div>
        </div>
      </form>
      <p className="mb-1 text-center"> <NavLink to="/user/login">Sign In</NavLink> </p>
    </div>
  </div>
</div>
          
       </div>
      
    );
};

export default ResetPassword;
