import React,{useState,useEffect } from 'react';
import {NavLink,useNavigate,useLocation,useParams   } from "react-router-dom";
import { useForm ,Controller} from 'react-hook-form';
import AdminHeader from '../layout/AdminHeader';
import AdminSidebar from '../layout/AdminSidebar';
import AdminFooter from '../layout/AdminFooter';
import common from '../services/common';
import Select from 'react-select';
import { Store } from 'react-notifications-component';
import Config from '../config/config';
import { Button,Modal } from 'react-bootstrap'

 

const EditLocation = () => {

    const [dropdownData, setDropdownData] = useState(null);
  
    const [show, setShow] = useState(false);
    const [userrole, setUserrole] = useState(null);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    var user_data = localStorage.getItem("user_data");
    var user_data_json = JSON.parse(user_data);

    let { id } = useParams();

 
   
    
    const [loading, setLoading] = useState(false);
    const [loginError, setLoginError] = useState();
    const [dropDownOpt, setdropDownOpt] = useState([]);  
    const [apiEdit, setApiEdit] = useState([])
    const navigate = useNavigate();

    const {
        control,
        register,
        getValues,
        handleSubmit,
        setValue,
        reset,
        formState: { errors }
      } = useForm({
        defaultValues: {apiEdit},
      });

   

      


      
      const onSubmit = async (data) => {
        setLoading(true); 

        // console.log(data);
        // return false;
    // let position_id_m = data.position_id.map(a => a.value);
    //     data.position_id=position_id_m;

        var UpdatePortalUserapi=   await common.AddorupdateLocation(data);  

        if(UpdatePortalUserapi?.data?.status==400)
        {
          navigate('/user/logout');
          return;
        }

     
       if(UpdatePortalUserapi?.data?.status==202)
       {
         Store.addNotification({
            title: "Success!",
            message: UpdatePortalUserapi?.data?.message,
            type: "success",           
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          });
          navigate('/admin/location',{state:{success:'1'}});
       
         setLoading(false);
       }
       else
       {

       // alert(UpdatePortalUserapi?.response?.message);

  


         setLoading(false);
     
         Store.addNotification({
            title: "Error!",
            message: UpdatePortalUserapi?.data?.message,
            type: "danger",            
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          });
         
        
        
    
      }
    }



    const deleteUser = async () => {
        setLoading(true); 

        var deleteid = new FormData();
        deleteid.append('location_id', id);

       // $("#delete-confirmation").modal("hide");
    
        var UpdatePortalUserapi=   await common.deletelocation(deleteid);  
     
       if(UpdatePortalUserapi?.data?.status==202)
       {
         Store.addNotification({
            title: "Success!",
            message: UpdatePortalUserapi?.data?.message,
            type: "success",           
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          });
          navigate('/admin/location',{state:{success:'1'}});
       
         setLoading(false);
       }
       else
       {

   

  


         setLoading(false);
     
         Store.addNotification({
            title: "Error!",
            message: UpdatePortalUserapi?.data?.message,
            type: "danger",            
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          });
         
        
        
    
      }
    }


    useEffect(() => {
          
    
        

        (async () => {
            clearInterval(Config.intervalId);

            if(!['1'].includes(user_data_json.role_id))
            {
              navigate('/user/login');
               return;
            }

            var dropdownvalue= await common.getdropdown(null);     
            
           

            setDropdownData(dropdownvalue.data.data)

        
            const dropDownValuedata = dropdownvalue.data.data.position_field.map((response) => ({
                "value" : response.position_id,
                "label" : response.name
              }))
              setdropDownOpt(dropDownValuedata);
              var  edituser= await common.editlocation(id);  
              setTimeout(() => {
                setApiEdit(edituser.data.data);
                reset(edituser.data.data);
              }, 0);
          })();
    
      }, [reset]);
  return (
    
        <div className="adminview wrapper">
          <AdminHeader />
          <AdminSidebar />
          
          <div className="content-wrapper px-3 pt-1 pb-3">
            <div className="content-header">
                <div className="row">
                    <div className="col-sm-6 pl-0">
                        <h1 className="m-0">
                            Edit Location
                            <small>
                            <NavLink to="/admin/location"><i className="fas fa-arrow-circle-left"></i> back to Location list</NavLink>
                            </small>
                        </h1>
                    </div>
                    <div className="col-sm-6 pr-0">
                        <div className="float-right">
                            <button type="submit" className="btn btn-primary" form='edit_user'>
                                <i className="far fa-save"></i> Save
                            </button>
                            {/* <span className="btn btn-danger" onClick={handleShow}>
                            <i className="far fa-trash-alt"></i> Delete</span> */}
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="form-horizontal">

                <div className="card card-secondary card-outline" data-card-name="customer-info" id="customer-info">
                    <div className="card-header with-border clearfix">
                        <div className="card-title"><i className="fas fa-info"></i>Location info</div>
                        <div className="card-tools float-right">
                            <button className="btn btn-tool" data-card-widget="collapse" type="button">
                                <i className="fa toggle-icon fa-minus"></i>
                            </button>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} id="edit_user">
                    <input   type="hidden"  {...register("user_id" )} value={id}/>
                    <div className="card-body">
                        <div className="row">

                      
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <div className="col-md-3">
                                        <div className="label-wrapper">
                                            <label className="col-form-label">Name <span className="required">*</span></label>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <input className="form-control text-box single-line" type="text"  {...register("name", { required: "Please enter name." })} />
                                        {errors.name?.message && (
     <p className='text-danger'>{errors.name.message}</p>
  )}

                                    </div>
                                </div>
                            </div>
                            


                    <div className="col-md-6">
                        <div className="form-group row">
                            <div className="col-md-3">
                                <div className="label-wrapper">
                                    <label className="col-form-label">Status <span className="required">*</span></label>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <select className="form-control" {...register('status', { required: "Please select status." })}>
                                <option value="">Please Select</option>
                                    <option value={1}>Enabled</option>
                                    <option value={0}>Disabled</option>
                                </select>
                                {errors.status?.message && (
     <p className='text-danger'>{errors.status.message}</p>
     )}

                            </div>
                        </div>
                            </div>



                        </div>

                        
                        
                    </div>
                    </form>
                </div>
            </div>

            
            <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>   <p>Are you sure you want to delete this Location?</p></Modal.Body>
        <Modal.Footer>
        <button type="submit" className="btn btn-danger" onClick={deleteUser}>Delete</button>
        <span className="btn btn-default" onClick={handleClose}>Cancel</span>
         
        </Modal.Footer>
      </Modal>

        </div>

        <AdminFooter />
       </div>
      
    );
};

export default EditLocation;
