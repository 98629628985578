import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, NavLink } from 'react-router-dom';
import { useAppState } from '../hooks/state';

const EmploymentApplicationStep3 = () => {
  const navigate = useNavigate();
  const [state, setState] = useAppState();
  const [selectedEducation, setSelectedEducation] = useState('');
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: state,
  });

  const [dynamicField, setDynamicField] = useAppState();

  const onSubmit = (data) => {
    console.log(data);
    setState({ ...state, ...data });
    navigate('/employment/background');
  };

  const handleRadioClick = (event) => {
    setSelectedEducation(event.target.value);
  };

  useEffect(() => {
    if (Object.keys(dynamicField).length === 0) {
      // navigate('/');
    }
  }, []);

  return (
    <div className="web-version">
      {/* ... Your header code ... */}
      <div className="web-page-content">
        <div className="container">
          <div className="web-page-inner">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="steps-content step-content-2">
                {/* ... Your progress bar ... */}
                <div className="card card-primary">
                  <div className="card-header with-border clearfix">
                    <div className="card-title">Education and Training</div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            What is your highest level of education completed?{' '}
                            <span className="required">*</span>
                          </label>
                          <div className="raw">
                            {dynamicField.highest_level_education_field &&
                              dynamicField.highest_level_education_field.map(
                                (hle, key) => (
                                  <div className="form-check" key={key}>
                                    <label className="form-check-label">
                                      <input
                                        type="radio"
                                        onClick={handleRadioClick}
                                        className="form-check-input"
                                        name="highest_level_education"
                                        value={hle.id}
                                        {...register('highest_level_education', {
                                          required: 'Please select education.',
                                        })}
                                      />
                                      {hle.name}
                                    </label>
                                  </div>
                                )
                              )}
                          </div>
                          {errors.highest_level_education?.message && (
                            <p className="text-danger">
                              {errors.highest_level_education.message}
                            </p>
                          )}
                        </div>
                      </div>
                      {selectedEducation === '7' && (
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Other</label>
                            <input
                              className="form-control text-box single-line"
                              type="text"
                              {...register('highest_level_education_other', {
                                validate: {
                                  required: (value) => {
                                    if (!value && selectedEducation === '7')
                                      return 'Please enter other education.';
                                    return true;
                                  },
                                },
                              })}
                            />
                            {errors.highest_level_education_other?.message && (
                              <p className="text-danger">
                                {errors.highest_level_education_other.message}
                              </p>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="card-footer-step text-center">
                  <NavLink className="btn btn-primary" to="/employment/position">
                    <i className="right fas fa-angle-left"></i> Previous
                  </NavLink>
                  <button type="submit" className="btn btn-primary">
                    Save & Next<i className="right fas fa-angle-right"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* ... Your footer code ... */}
    </div>
  );
};

export default EmploymentApplicationStep3;
